import React from "react";
import { Typography, Button, Container, TextField } from "@mui/material";

const containerStyle = {
    marginTop: "2rem",
    padding: "2rem",
    width: "90%",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

const formStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

function JoinArea(props){
    return (
        <>
            <Container maxWidth="sm" style={containerStyle}>
                <Typography variant="h4" align="center" gutterBottom>
                    Join a Game
                </Typography>
                <form style={formStyle}>
                    <TextField
                        label="Game Code"
                        variant="outlined"
                        required
                        margin="normal"
                        fullWidth
                    />
                    <Button variant="contained" color="primary" fullWidth size="large" onClick={() => {props.setJoined(true)}}>
                        Join
                    </Button>
                </form>
            </Container>
        </>
    );
}

export default JoinArea;