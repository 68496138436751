import React, { useState } from "react";
import {
    Container,
    Typography,
    Paper,
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
} from "@mui/material";

const options = [
    { text: "Option A", color: "red" },
    { text: "Option B", color: "green" },
    { text: "Option C", color: "blue" },
    { text: "Option D", color: "purple" },
];

function QuizQuestion() {
    const [selectedOption, setSelectedOption] = useState("");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <Container style={{
        	position: "absolute",
    		top: "50%",
   			left: "50%",
    		transform: "translate(-50%, -50%)"
    	}} maxWidth="sm">
            <Paper elevation={3} style={{ padding: "1.5rem" }}>
                <Typography variant="h5" gutterBottom>
                    Q: Which is the richest country in the world?
                </Typography>

                <FormControl component="fieldset">
                    <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                        {options.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                value={option.text}
                                control={<Radio style={{ color: option.color }} />}
                                label={option.text}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        console.log("Selected Option:", selectedOption);
                    }}
                    disabled={!selectedOption}
                >
                    Submit
                </Button>
            </Paper>
        </Container>
    );
}

export default QuizQuestion;