import React from "react";
import { Button } from "@mui/material";

function Scores(){
    return (
        <div
            style={{
                position: "fixed",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: "1000",
            }}
        >
            <Button variant="contained">
                Show Scores
            </Button>
        </div>
    );
};

export default Scores;
