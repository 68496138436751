import React from "react";

function Points(){
    return (
        <div
            style={{
                position: "fixed",
                top: "80px",
                left: "20px",
                padding: "10px",
                background: "#2196F3",
                color: "#FFFFFF",
                borderRadius: "5px",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                zIndex: "1000",
            }}
        >
            Points: 20
        </div>
    );
};

export default Points;