import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

function Appbar(){
    return (
        <AppBar position="fixed">
            <Toolbar>
                <Typography variant="h6"> Game Show </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Appbar;