import React, {useState} from "react";
import Appbar from "./Appbar";
import JoinArea from "./JoinArea";
import QuizQuestion from "./QuizQuestion";
import Points from "./Points";
import Timer from "./Timer";
import Scores from "./Scores";

function App(){
    const [joined, setJoined] = useState(false);

    return (
        <>
            <Appbar/>
            {!joined && <JoinArea setJoined={setJoined}/>}
            {joined && <QuizQuestion/>}
            {joined && <Points/>}
            {joined && <Timer/>}
            {joined && <Scores/>}
        </>
    );
}

export default App;